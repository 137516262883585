var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = true;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;
// $(document).ready(function () {
//   $('.pageHeader').wrap('<div class="pageHeaderWrapper"></div>');
// });


$('.mainLogo').attr('href', 'https://missio.org.uk/');

if (window.location.pathname === "/") {
  window.location.href = "/appeals";
}

if (($('body').hasClass('appealsList'))) {
  $("li.cpt.tab a").text("Donate Now");
  $("li.ft.tab a").text("Fundraise");
}

if (($('body').hasClass('appealsPostBody'))) {
  $(".appealActionsWrapper").prependTo(".appealWidgets");
}

if ($('body').hasClass('pagesPostBody') || $('body').hasClass('newsPostBody') || $('body').hasClass('eventsPostBody') || $('body').hasClass('appealsPostBody')) {
  $(".carouselSlide").prependTo(".postContent");
}